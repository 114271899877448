/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes formations';
export const txtnombredoc = 'formation';
export const txtnombredocpluriel = 'formations';
export const titleAlveole = 'Mes formations';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-formations.svg";

/************************ DeV ******************************************************/
// Récupération des infos WP
export const zAPIFormation = "https://api-nosformations-dev.cristal-hub.fr/api/formations";
export const zAPIEtiquettes = "https://api-nosformations-dev.cristal-hub.fr/api/etiquettes";
export const zAPILabels = "https://api-nosformations-dev.cristal-hub.fr/api/labels";

export const zUrlBack= 'https://api-nosformations-dev.cristal-hub.fr';
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";

/************************ LOCAL DeV ************************************************/

// export const zAPIFormation = "https://localhost:8000/api/formations";
// export const zAPIEtiquettes = "https://localhost:8000/api/etiquettes";
// export const zAPILabels = "https://localhost:8000/api/labels";

// export const zUrlBack = "https://localhost:8000"
// export const zUrlBackNotifications = "https://localhost:8001";

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
